// Generated by Framer (c126521)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/X1dK_fpxD";

const cycleOrder = ["BtRTuTezm", "ELy9t6Agf"];

const serializationHash = "framer-NiWnS"

const variantClassNames = {BtRTuTezm: "framer-v-f16fj5", ELy9t6Agf: "framer-v-soddo1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Large: "BtRTuTezm", Small: "ELy9t6Agf"}

const getProps = ({background, height, id, text, width, ...props}) => { return {...props, dI2V912ba: background ?? props.dI2V912ba ?? "var(--token-6aa0c73c-cfba-499c-bfa2-5a2a88507ddb, rgba(237, 235, 232, 0.48))", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "BtRTuTezm", YbsaeEF4R: text ?? props.YbsaeEF4R ?? "Badge"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;text?: string;background?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, YbsaeEF4R, dI2V912ba, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "BtRTuTezm", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = [sharedStyle.className]

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-f16fj5", className, classNames)} data-border data-framer-name={"Large"} layoutDependency={layoutDependency} layoutId={"BtRTuTezm"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgba(255, 255, 255, 0.1)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backdropFilter: "blur(5px)", backgroundColor: dI2V912ba, borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, WebkitBackdropFilter: "blur(5px)", ...style}} {...addPropertyOverrides({ELy9t6Agf: {"data-framer-name": "Small"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-13udwc7"} data-styles-preset={"X1dK_fpxD"}>Badge</motion.p></React.Fragment>} className={"framer-ubcma7"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"mP01sdrST"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={YbsaeEF4R} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-NiWnS.framer-d279bj, .framer-NiWnS .framer-d279bj { display: block; }", ".framer-NiWnS.framer-f16fj5 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; overflow: hidden; padding: 12px 16px 12px 16px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-NiWnS .framer-ubcma7 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-NiWnS.framer-f16fj5 { gap: 0px; } .framer-NiWnS.framer-f16fj5 > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-NiWnS.framer-f16fj5 > :first-child { margin-left: 0px; } .framer-NiWnS.framer-f16fj5 > :last-child { margin-right: 0px; } }", ".framer-NiWnS.framer-v-soddo1.framer-f16fj5 { padding: 8px 12px 8px 12px; }", ...sharedStyle.css, ".framer-NiWnS[data-border=\"true\"]::after, .framer-NiWnS [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 42
 * @framerIntrinsicWidth 72
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"ELy9t6Agf":{"layout":["auto","auto"]}}}
 * @framerVariables {"YbsaeEF4R":"text","dI2V912ba":"background"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramergpV4gxmeP: React.ComponentType<Props> = withCSS(Component, css, "framer-NiWnS") as typeof Component;
export default FramergpV4gxmeP;

FramergpV4gxmeP.displayName = "Elements/Badge 2";

FramergpV4gxmeP.defaultProps = {height: 42, width: 72};

addPropertyControls(FramergpV4gxmeP, {variant: {options: ["BtRTuTezm", "ELy9t6Agf"], optionTitles: ["Large", "Small"], title: "Variant", type: ControlType.Enum}, YbsaeEF4R: {defaultValue: "Badge", displayTextArea: false, title: "Text", type: ControlType.String}, dI2V912ba: {defaultValue: "var(--token-6aa0c73c-cfba-499c-bfa2-5a2a88507ddb, rgba(237, 235, 232, 0.48)) /* {\"name\":\"Navigation\"} */", title: "Background", type: ControlType.Color}} as any)

addFonts(FramergpV4gxmeP, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})